import { lazy, Suspense, Fragment } from "react";
import { Routes, Route } from "react-router-dom";
import Header from "./Components/Header";
import { Container } from "react-bootstrap";
import Footer from "./Components/Footer";

const AboutUsScreen = lazy(() => import("./Screens/AboutUsScreen"));
const HomeScreen = lazy(() => import("./Screens/HomeScreen"));
const MetalScreen = lazy(() => import("./Screens/MetalScreen"));
const LogisticsScreen = lazy(() => import("./Screens/LogisticsScreen"));
const SealScreen = lazy(() => import("./Screens/SealScreen"));
const MissionScreen = lazy(() => import("./Screens/MissionScreen"));
const ServicesScreen = lazy(() => import("./Screens/ServicesScreen"));
const LCSolutions = lazy(() => import("./Screens/LCSolutions"));
const Industries = lazy(() => import("./Screens/Industries"));
const ContactUsScreen = lazy(() => import("./Screens/ContactUsScreen"));

function App() {
  return (
    <Fragment   >
      <>
      <Header />
      <main>
      <Container fluid style={{padding:'0'}} >
      <Suspense fallback={<div>Loading...</div>}>
      <Routes>
        <Route path="/" element={<HomeScreen />}  />
        <Route path="/aboutus" element={<AboutUsScreen/>}/>
        <Route path="/information" element={<MissionScreen />} />
        <Route path="/services" element={<ServicesScreen />} />
        <Route path="/metals" element={<MetalScreen />} />
        <Route path="/seals" element={<SealScreen />} />
        <Route path="/logistics" element={<LogisticsScreen />} />
        <Route path="/lcs" element={<LCSolutions />} />
        <Route path="/industries" element={<Industries />} />
        <Route path="/contact" element={<ContactUsScreen />} />
      </Routes>
      
      </Suspense>
      </Container>
      </main>
      <Footer/> 
      
      </>
    </Fragment>

  );
}

export default App;
